import { reactive, ref } from 'vue';

export const isVisibleContextMenu = ref(false);
export const positionContextMenu = reactive({
  x: 0,
  y: 0,
});
export const contextMenuItems = ref([]);
export const clickedItemInContextMenu = ref({});

export const useContextMenu = () => {
  const setMenuItems = menuItems => {
    contextMenuItems.value = menuItems;
  };

  const show = (event, clickedItem) => {
    positionContextMenu.x = event.clientX - 4;
    positionContextMenu.y = event.clientY - 25;
    isVisibleContextMenu.value = true;
    clickedItemInContextMenu.value = clickedItem;
  };

  return {
    setMenuItems,
    showContextMenu: show,
  };
};
