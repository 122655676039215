import { useStore } from '@/app/use/store';
import { filterObject } from '@/app/helpers/filter-object';

const DEFAULT_COLUMNS = [
  {
    title: 'Id',
    key: 'id',
    isVisible: true,
  },
];
const { store, setData, reset } = useStore(DEFAULT_COLUMNS);

export const useColumn = () => {
  const resetColumnFilter = () => {
    Object.keys(store.value).forEach(e => (store.value[e].isVisible = false));
  };

  const filter = columnsVisible => {
    if (columnsVisible) {
      resetColumnFilter();
      const visibleColumnIds = columnsVisible.split(',');
      visibleColumnIds.forEach(columnId => {
        store.value[columnId].isVisible = true;
      });
    }

    return filterObject(store.value, 'isVisible', false);
  };

  const setColumnData = data => {
    reset();
    setData(data);
  };

  return {
    columnsData: store,
    setColumnData,
    clearColumnData: reset,
    filterColumns: filter,
  };
};
