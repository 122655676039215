<template>
  <n-dropdown
    placement="bottom-start"
    trigger="manual"
    :x="positionContextMenu.x"
    :y="positionContextMenu.y"
    :options="contextMenuItems"
    :show="isVisibleContextMenu"
    size="large"
    :on-clickoutside="onClickOutside"
    @select="handleSelect"
  />
</template>

<script>
import {
  clickedItemInContextMenu,
  contextMenuItems,
  isVisibleContextMenu,
  positionContextMenu,
} from '@/app/use/contextMenu';

export default {
  name: 'ContextMenu',
  setup() {
    const onClickOutside = () => {
      isVisibleContextMenu.value = false;
    };

    const handleSelect = action => {
      action(clickedItemInContextMenu);
      isVisibleContextMenu.value = false;
    };

    return {
      contextMenuItems,
      isVisibleContextMenu,
      positionContextMenu,
      onClickOutside,
      handleSelect,
    };
  },
};
</script>

<style scoped></style>
