<template>
  <div class="columns-filter-wrapper">
    <Icon name="view_column" @click="ontoggle" />
    <div v-if="isDropdownVisible" class="dropdown-list">
      <div v-for="column in columnsData" :key="column.title" class="dropdown-row">
        <n-switch v-model:value="column.isVisible" size="small" @update:value="handleChange()" />
        <p class="column-title">{{ column.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/app/components/base/Icon';
import { NSwitch } from 'naive-ui';
import { ref } from 'vue';
import { useQuery } from '@/app/use/router';
import { useColumn } from '@/app/use/columns';
import { filterObject } from '@/app/helpers/filter-object';

export default {
  name: 'TableColumnsFilter',
  components: { Icon, NSwitch },
  setup() {
    const isDropdownVisible = ref(false);
    const { update } = useQuery();

    const { columnsData } = useColumn();

    const ontoggle = () => {
      isDropdownVisible.value = !isDropdownVisible.value;
    };

    const handleChange = () => {
      const filteredObject = filterObject(columnsData.value, 'isVisible', false);
      update({
        columns: Object.keys(filteredObject).join(),
      });
    };

    return {
      ontoggle,
      isDropdownVisible,
      columnsData,
      handleChange,
    };
  },
};
</script>

<style scoped lang="scss">
.columns-filter-wrapper {
  position: relative;
}

.dropdown-list {
  position: absolute;
  right: 0;
  z-index: 1000;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  padding: 1rem;
  background-color: #fff;
  text-align: left;
}

.dropdown-row {
  display: flex;
  align-items: center;
  line-height: 1.2;
  margin: 1rem 0;
}

.column-title {
  margin-left: 1rem;
}
</style>
